<template>
  <div>
    <el-divider/>
    <el-row>
      <el-col :span="4">
        <el-button @click="revokeBtn" type="warning"
                   v-if="userPermissions.indexOf('bonus_revoke') !== -1 && bonus_bill.status > 1"><i
            class="iconfont icon-fanshenhe"></i> 撤回重审
        </el-button>
        <el-button v-else disabled>
          <i class="iconfont icon-fanshenhe"></i> 撤回重审
        </el-button>
      </el-col>
      <el-col :span="20" style="text-align: right">
        <el-tag :type="checkStatus()['type']" effect="plain" class="status-tag" size="large">
          状态 - {{
            checkStatus()['label']
          }}
        </el-tag>

        <!--        <el-button type="warning" size="small" @click="averageRatio" :disabled="!canAverage()">均分</el-button>-->
        <el-button :type="`${unsaved?'success':'default'}`" @click="saveConfirm('save')"
                   :disabled="disabledSave"
                   v-if="userPermissions.indexOf('bonus_save') !== -1">保存
        </el-button>
        <el-button type="primary" @click="commitToNext()" v-if="bonus_bill.status === 0">提交总监审核</el-button>
        <el-button :type='`${bonus_bill.status && bonus_bill.status > 1 ? "success" : "default"}`'
                   @click="saveConfirm('check_1')" :disabled="!canCheckFirst()"
                   v-if="userPermissions.indexOf('bonus_check_1') !== -1">
          {{ bonus_bill.status && bonus_bill.status > 1 ? '总监审核完毕' : "总监审核" }}
        </el-button>
        <el-button :type='`${bonus_bill.status && bonus_bill.status > 2 ? "success" : "default"}`'
                   @click="saveConfirm('check_2')" :disabled="!canCheckSecond()"
                   v-if="userPermissions.indexOf('bonus_check_2') !== -1">
          {{ bonus_bill.status && bonus_bill.status > 2 ? "人资审核完毕" : "人资审核" }}
        </el-button>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "BonusHandle",
  computed: {
    ...mapGetters(['userPermissions', 'buttonType']),
    disabledSave() {
      // 不能保存的条件：已保存或者状态为：人资已审核
      return this.bonus_bill.status === 3 || this.currentDataList.length === 0
    }
  },
  props: {
    group_id: {
      type: String,
      default() {
        return undefined;
      }
    },
    ym: {
      type: String,
      default() {
        return undefined;
      }
    },
    currentBonusBill: {
      type: Object,
      default() {
        return {}
      }
    },
    currentDataList: {
      type: Array,
      default() {
        return []
      }
    }
  },
  watch: {
    currentBonusBill: {
      deep: true,
      handler(val) {
        this.bonus_bill = {...val}
      }
    },
    currentDataList: {
      deep: true,
      handler(val) {
        this.dataList = val
        this.unsaved = true
      }
    },

  },
  data() {
    return {
      unsaved: false,
      showEditIcon: true,
      bonus_bill: {},
      dataList: []
    }
  },
  methods: {
    //总监审核
    canCheckFirst() {
      return this.bonus_bill && this.bonus_bill.status === 1
    },
    //人资审核
    canCheckSecond() {
      return this.bonus_bill && this.bonus_bill.status === 2
    },
    checkStatus() {
      if (this.bonus_bill.status === 3)
        return {type: 'success', label: '已完结'}
      else if (this.bonus_bill.status === 2) {
        return {type: 'warning', label: '待人资审核'}
      } else if (this.bonus_bill.status === 1) {
        return {type: 'warning', label: '待总监审核'}
      } else {
        return {type: 'info', label: '待提交总监审核'}
      }
    },
    //确认操作
    saveConfirm(mode) {
      let modeMsg = '';
      if (mode === 'save') {
        modeMsg = '保存'
      } else if (mode === 'check_1') {
        modeMsg = '总监审核'
      } else if (mode === 'check_2') {
        modeMsg = '人资审核'
      }

      let msg = `确认${modeMsg}【当前分组】【${this.ym}】奖金分配明细, 是否继续?`;

      this.$confirm(msg, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        if (mode === 'save') {
          this.commitSave()
        } else {
          if (this.unsaved) {

            this.$confirm('编辑内容未保存，是否保存', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {

              this.commitCheck(mode, true)
            }).catch(() => {
              this.$message({
                type: 'info',
                message: '已取消'
              });
            });
          } else {
            this.commitCheck(mode)
          }
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });

    },
    //确认-提交保存信息
    async commitSave() {
      this.commitLoading = true
      let postData = {
        search_condition: {id: this.bonus_bill.id,},
        data: this.dataList
      }
      let info = await this.$api.saveBonusBill(postData)
      this.commitLoading = false
      if (info === 'ok') {
        if (this.bonus_bill.status === 0) {
          this.$confirm(`保存成功，是否【提交-总监审核】?`, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '再想想',
            type: 'warning'
          }).then(() => {
            this.commitBill()
          }).catch(() => {
            this.$message({
              type: 'info',
              message: '已取消【提交-总监审核】'
            });
          });
        } else {
          this.$notify.success('保存成功')
          this.saved()
        }

      }
      this.unsaved = false
    },

    async commitCheck(mode, save) {
      if (save) {
        await this.commitSave()
      }
      let postData = {
        id: this.bonus_bill.id,
        check_status: mode
      }
      let info = await this.$api.checkBonusBill(postData)
      if (info === 'ok') {
        this.$notify.success('审核成功')
        this.saved()
      }
    },

    revokeBtn() {
      this.$confirm(`此操作将撤回审核状态至[待总监审核], 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.revokeBill()
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },

    async revokeBill() {
      let postData = {
        id: this.bonus_bill.id,
      }
      await this.$api.revokeBonusBill(postData)
      this.saved()
    },

    saved() {
      this.$emit('saved')
    },
    async commitBill() {
      //提交待总监审核
      let postData = {
        id: this.bonus_bill.id,
      }
      await this.$api.commitBonusBill(postData)
      this.saved()
    },
    commitToNext() {
      //
      this.$confirm(`此操作将确认【提交-总监审核】, 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '再想想',
        type: 'warning'
      }).then(() => {
        this.commitBill()
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消【提交-总监审核】'
        });
      });
    }
  }
}
</script>

<style scoped>

.status-tag {
  margin-right: 10px;
}
</style>
