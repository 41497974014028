<template>
  <div id="menu-comm">
    <div v-show="menuData.visible" :style="{left:menuData.left+'px',top:menuData.top+'px'}" class="menu">
      <div class="contextmenu__item"
           @click="checkEvent('Y')">
        显示 &nbsp;<i class="el-icon-check"></i>
      </div>
      <div class="contextmenu__item"
           @click="checkEvent('N')">隐藏&nbsp;<i class="el-icon-close"></i>
      </div>
      <div class="contextmenu__item" @click="checkEvent('D')" v-if="userPermissions.indexOf('bonus_delete_members')>-1">
        删除&nbsp;<i class="el-icon-delete"></i>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "Contextmenu",
  props: {
    menuData: {
      type: Object,
      default() {
        return {
          visible: false,
          top: 0,
          left: 0
        }
      }
    }
  },
  computed: {
    ...mapGetters(['userPermissions']),
  },
  watch: {
    menuData: {
      deep: true,
      handler() {
        // this.initList()
      }
    }
  },
  data() {
    return {
      menuVisible: false,
    }
  },
  methods: {
    closeMenu() {
      this.menuData.visible = false;
    },
    checkEvent(val) {
      this.$emit('change', val)
      this.menuData.visible = false;
    },
  }
}
</script>

<style scoped>

.contextmenu__item {
  display: block;
  line-height: 34px;
  text-align: center;
}

.contextmenu__item:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.menu {
  position: absolute;
  background-color: #fff;
  width: 100px;
  /*height: 106px;*/
  font-size: 14px;
  color: #444040;
  border-radius: 4px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  white-space: nowrap;
  z-index: 1000;
}

.contextmenu__item:hover {
  cursor: pointer;
  background: #ff3176;
  border-color: #ff3176;
  color: #fff;
}
</style>
